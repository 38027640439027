/* .solution-container {
    max-width: 1200px;
    margin: auto;
    padding: 2rem;
    color: #ffffff;
  } */
  
  .solution-title {
    font-size: 3rem;
    color: #333333; /* Gold color for the title */
    margin-bottom: 1rem;
  }
  
  .solution-tagline {
    font-size: 1.5rem;
    color: #333333; /* Gold color for the title */
    margin-bottom: 2rem;
  }
  
  .solution-section {
    margin-bottom: 2rem;
  }
  
  .solution-heading {
    font-size: 2rem;
    color: #333333; /* Gold color for the title */
    margin-bottom: 1rem;
  }
  
  .solution-list {
    list-style-type: none;
    padding: 0;
  }
  
  .solution-list li {
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: #333333; /* Gold color for the title */

  }
  
  .solution-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .tlp-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    height: 100vh;
  }

  .tlp-form{
    width: 60%;
  }
  
  /* Add responsive design for smaller screens */
  @media (max-width: 768px) {
    .solution-container {
      padding: 1rem;
    }

    .tlp-form{
      width: 90%;
    }
  
    .solution-title {
      font-size: 2.5rem;
    }
  
    .solution-heading {
      font-size: 1.5rem;
    }
  
    .solution-description {
      font-size: 1rem;
    }
  }
  