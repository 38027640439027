/* Container styles */
div.dashboard-container {
  margin-left: 305px;
  max-width: calc(100% - 305px);
}

.mobile-navbar-toggle {
  display: none;
}

.navbar-close-button {
  display: none;
}

/* Typography styles */
h1.dashboard-heading {
  font-size: 48px;
  margin-top: 30px;
  color: #6d7580;
}

h2.dashboard-subheading {
  font-size: 24px;
  color: #858c94;
}

.summarisation-note {
  font-weight: 200;
  font-size: 10px;
  color: grey;
  margin-top: 10px;
}

/* Box styles */
div.dashboard-box {
  display: flex;
  gap: 20px;
}

div.connect-box,
div.status-box {
  border-radius: 8px;
  padding: 0px 13px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
}

div.icon-container {
  background-color: #f4f7fe;
  padding: 15px;
  border-radius: 25px;
}

p.connect-text,
p.status-text {
  font-size: 12px;
  color: #000;
}

p.zoom-status {
  font-size: 20px;
  font-weight: 300;
  color: #1b2559;
  text-align: left;
  line-height: 1;
  padding: 0
}

.count-box {
  display: flex;
  border-radius: 8px;
  padding: 0px 13px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

p.conversation-count,
p.pending-meetings {
  font-size: 36px;
  font-weight: lighter;
  color: #1b2559;
}

/* Link styles */
a.dashboard-link {
  text-decoration: none;
  font-size: 24px;
  font-weight: 400;
  color: #1b2559;
}

/* Table styles */
div.dashboard-table-container {
  background-color: white;
}

tr.dashboard-table-row {
  background-color: white;
}

td.dashboard-table-cell {
  color: black;
}

/* Typography styles */
p.dashboard-footer-text {
  font-weight: 200;
  color: grey;
  margin-top: 10px;
}

/* Button styles */
button.dashboard-button {
  padding: 10px;
  margin-top: 20px;
  background-color: black;
  color: white;
  border-radius: 8px;
  font-size: 18px;
}

.navbar-hidden {
  display: none;
}

.conversation-box {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.conversation-box-sub {
  width: "65%";
}

.command-center {
  width: "32%";
}

.no-conversations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .navbar.navbar-hidden {
    display: none;
  }

  .navbar.navbar-visible {
    display: block;
  }
  div.dashboard-container {
    margin-left: 20px;
  }

  /* Typography adjustments */
  h1.dashboard-heading {
    font-size: 32px; /* Smaller font size */
    margin-top: 20px;
  }

  h2.dashboard-subheading {
    font-size: 18px;
  }

  p.connect-text,
  p.status-text,
  p.conversation-count,
  p.pending-meetings {
    font-size: smaller; /* Adjust font sizes */
    text-align: left;
  }

  /* Box layout adjustments */
  div.dashboard-box {
    flex-direction: column; /* Stack elements vertically */
    gap: 10px;
  }

  /* Margin and padding adjustments */
  div.dashboard-container {
    margin-left: 5px;
    max-width: calc(100% - 10px); /* Adjust max-width */
  }

  div.connect-box,
  div.status-box,
  div.icon-container {
    padding: 5px; /* Reduced padding */
    width: 100;
    justify-content: flex-start;
  }

  /* Adjust link font size */
  a.dashboard-link {
    font-size: 24px;
  }

  /* Button adjustments */
  button.dashboard-button {
    padding: 5px;
    font-size: 16px;
  }

  /* Handle table layouts */
  .dashboard-table-container {
    overflow-x: auto;
  }

  .mobile-navbar-toggle {
    margin: 10px 0px;
    display: flex;
  }

  .navbar-close-button {
    display: flex;
  }

  .count-box {
    display: none;
  }

  /* Adjust modal styles for mobile */
  .dialog-content-text,
  .dialog-actions {
    font-size: 14px;
  }

  /* Joyride adjustments for mobile */
  .joyride-tooltip {
    max-width: 90%; /* Prevents overflow */
  }

  /* General typography adjustments */
  h1,
  h2,
  h6,
  p {
    font-size: smaller; /* Adjust font size for all text elements */
  }

  /* Adjust input and button sizes */
  input,
  button {
    font-size: 14px;
    padding: 8px;
  }

  .conversation-box {
    flex-direction: column;
  }

  .conversation-box-sub {
    width: "100%";
  }

  .command-center {
    width: "100%";
  }
}
