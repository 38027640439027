.home-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Change to match your color scheme */
  padding: 3vh 3vw; /* Adjust padding as needed */
}

.spinning-logo {
  height: 7vh; /* Adjust the size as needed */
}

.menu-links {
  display: flex;
  align-items: center;
}

.menu-link {
  margin-left: 30px; /* Adjust spacing between links as needed */
  text-decoration: none;
  color: #333; /* Change to match your color scheme */
  font-size: 18px; /* Adjust font-size as needed */
  font-family: "Montserrat", sans-serif;
  color: #696969;
  font-weight: 500;
}
.menu-link-login {
  margin-left: 30px; /* Adjust spacing between links as needed */
  text-decoration: none;
  color: #333; /* Change to match your color scheme */
  font-size: 18px; /* Adjust font-size as needed */
  font-family: "Montserrat", sans-serif;
  color: #696969;
  font-weight: 500;
}

.menu-link-start {
  margin-left: 30px; /* Adjust spacing between links as needed */
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-size: 18px; /* Adjust font-size as needed */
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 500;
  border: #000 solid 2px;
  border-radius: 6px;
  padding: 8px 10px;
  background-color: #000;
}

.menu-link:hover {
  color: #000; /* Change to match your color scheme */
  text-decoration: underline;
}

/* Responsive design: Adjusts layout for smaller screens */
@media (max-width: 768px) {
  .home-navbar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .menu-links {
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px; /* Add space between logo and links */
    align-items: center;
  }

  .menu-link {
    display: none;
  }

  .menu-link-start {
    display: none;
  }

  .spinning-logo {
    height: 5vh; /* Adjust the size as needed */
  }
}
